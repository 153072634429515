// ------------------------------
// BUTTONS
// ------------------------------

.btn {
  padding: 10px 20px;
  border: 1px solid $primary-color;
  border-radius: 20px;
  font-size: .9em;
  font-weight: bold;
  letter-spacing: 1px;
  text-shadow: none;
  color: $primary-color;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: darken($primary-color, 15%);
    border-color: darken($primary-color, 15%);
  }

}

.btn-border-small {
  border: 1px solid $primary-color;
  border-radius: 20px;
  padding: 6px 8px;
  font-size: .8em;
  margin-left: 10px;
}

.btn-secondary {
  border-color: $secondary-color;
  color: $secondary-color;

  &:hover {
    color: darken($secondary-color, 15%);
    border-color: darken($secondary-color, 15%);
  }

}

.btn-tertiary {
  border-color: $gray-dark;
  color: $gray-dark;

  &:hover {
    color: darken($gray-dark, 15%);
    border-color: darken($gray-dark, 15%);
  }

}

.btn-large {
  padding: 10px 24px;
  font-size: 1.1em;
}

.btn-small {
  padding: 8px 12px;
  font-size: .7em;
}

// ------------------------------
// MOBILE MENU BUTTON
// ------------------------------

.btn-mobile-menu {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: rgba($gray-darkest,.98);
  border-bottom: 1px solid rgba(255,255,255,.1);
  text-align: center;
}

.btn-mobile-menu__icon,
.btn-mobile-close__icon {
  position: relative;
  top: 10px;
  color: #FFF;
}