// ------------------------------
// MEDIA QUERIES
// ------------------------------

@media all and (min-width: 1300px) {

}

// ------------------------------
// < 1100px
// ------------------------------

@media all and (max-width: 1100px) {

  .panel-cover__logo {
    width: 70px;
  }

  .panel-title {
    font-size: 2em;
  }

  .panel-subtitle {
    font-size: 1em;
  }

  .panel-cover__description {
    margin: 0 10px;
    font-size: .9em;
  }

  .navigation--social {
    margin-top: 5px;
    margin-left: 0;
  }

}

// ------------------------------
// < 960px
// ------------------------------

@media all and (max-width: 960px) {

  .btn-mobile-menu {
    display: block;
  }

  .panel-main {
    display: table;
    position: relative;
  }

    .panel-cover--collapsed {
      width: 100%;
      max-width: none;
    }

  .panel-main__inner {
    display: table-cell;
    padding: 60px 10%;
  }

  .panel-cover__description {
    display: block;
    max-width: 600px;
    margin: 0 auto;
  }

  .panel-cover__divider--secondary {
    display: none;
  }

  .panel-cover {
    width: 100%;
    height: 100%;
    background-position: center center;

    &.panel-cover--collapsed {
      display: block;
      position: relative;
      height: auto;
      padding: 0;
      background-position: center center;

      .panel-main__inner {
        display: block;
        padding: 70px 0 30px 0;
      }

      .panel-cover__logo {
        width: 60px;
        border-width: 2px;
      }

      .panel-cover__description {
        display: none;
      }

      .panel-cover__divider {
        display: none;
        margin: 1em auto;
      }

    }

  }

    .navigation-wrapper {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      padding: 20px 0;
      background: rgba($gray-darkest,.98);
      border-bottom: 1px solid rgba(255,255,255,.15);

      &.visible {
        display: block;
      }
    }

    .cover-navigation {
      display: block;
      position: relative;
      float: left;
      clear: left;
      width: 100%;

      .navigation {
        display: block;
        width: 100%;

        li {
          width: 80%;
          margin-bottom: .4em;
        }

      }

      &.navigation--social {
        padding-top: 5px;

        .navigation {

          li {
            display: inline-block;
            width: 25.8%;
          }

        }

      }

    }

    .content-wrapper {
      width: 80%;
      max-width: none;
      margin: 0 auto;
    }

    .content-wrapper__inner {
      margin-right: 0;
      margin-left: 0;
    }

    .navigation__item {
      width: 100%;
      margin: 0 0 .4em 0;
    }

}

// ------------------------------
// < 340px
// ------------------------------

@media all and (max-width: 340px) {

  .panel-main__inner {
    padding: 0 5%;
  }

  .panel-title {
    margin-bottom: .1em;
    font-size: 1.5em;
  }

  .panel-subtitle {
    font-size: .9em;
  }

  .btn {
    display: block;
    margin-bottom: .4em;
  }

}

@media (prefers-color-scheme: dark) {
	body {
		background-color: $dark-background-color;
		color: darken($gray, 5%);
	}



h1,
h2,
h3,
h4,
h5 {
	color: $gray;
}

a {
        &:hover {
            color: $dark-hover-color;
        }
    }

    .post-list__post-title {
        a {
            color: $gray;
            &:hover {
                color: $dark-hover-color;
            }
            }
    }

    .post-list__divider {
        border-top: 1px solid $gray-dark;
    }

    table {
        color: $gray-dark;
        border: 2px solid darken($primary-color, 20%);
        thead tr:first-child {
            background-color: darken($primary-color, 20%);
            color: $gray;
        }
        thead tr:last-child th {
            border-bottom: 1px solid $gray-dark;
        }
        tbody td {
            border-bottom: 1px solid $gray-dark;
        }
    }

    summary {
        background: lighten($dark-background-color, 10%);
        color: $gray-dark;
    }

    details {
        background: darken($gray-darkest, 5%);
    }

    details[open] summary {
        background: darken($primary-color, 20%);
        color: $gray-dark;
    }

    details p {
        color: $gray-darker;
    }

    code {
        background: lighten($gray-darkest, 7%);
    }

    pre {
        background: darken($gray-darkest, 5%);
        border: none;
    }

    .highlight {
        .n {
            color: $gray-dark;
        }
        .nv {
            color: $gray-dark;
        }
        .p {
            color: darken($gray-dark, 10%);
        }
    }

    .read-more-item-dim {
        color: $dark-hover-color;
    }
}


@media (prefers-color-scheme: light) {
	body {
		background-color: $dark-background-color;
		color: darken($gray, 5%);
	}



h1,
h2,
h3,
h4,
h5 {
	color: $gray;
}

a {
        &:hover {
            color: $dark-hover-color;
        }
    }

    .post-list__post-title {
        a {
            color: $gray;
            &:hover {
                color: $dark-hover-color;
            }
            }
    }

    .post-list__divider {
        border-top: 1px solid $gray-dark;
    }

    table {
        color: $gray-dark;
        border: 2px solid darken($primary-color, 20%);
        thead tr:first-child {
            background-color: darken($primary-color, 20%);
            color: $gray;
        }
        thead tr:last-child th {
            border-bottom: 1px solid $gray-dark;
        }
        tbody td {
            border-bottom: 1px solid $gray-dark;
        }
    }

    summary {
        background: lighten($dark-background-color, 10%);
        color: $gray-dark;
    }

    details {
        background: darken($gray-darkest, 5%);
    }

    details[open] summary {
        background: darken($primary-color, 20%);
        color: $gray-dark;
    }

    details p {
        color: $gray-darker;
    }

    code {
        background: lighten($gray-darkest, 7%);
    }

    pre {
        background: darken($gray-darkest, 5%);
        border: none;
    }

    .highlight {
        .n {
            color: $gray-dark;
        }
        .nv {
            color: $gray-dark;
        }
        .p {
            color: darken($gray-dark, 10%);
        }
    }

    .read-more-item-dim {
        color: $dark-hover-color;
    }
}
