nav {
  display: inline-block;
  position: relative;
}

.navigation {
  display: inline-block;
  float: left;
  position: relative;
  margin: 0;
  list-style-type: none;
}

  .navigation__item {
    display: inline-block;
    margin: 5px 1px 0 0;
    line-height: 1em;

    a {
      display: block;
      position: relative;
      @extend .btn;
      border-color: #FFF;
      color: #FFF;
      opacity: .8;

      &:hover {
        color: #FFF;
        border-color: #FFF;
        opacity: 1;
      }

    }

  }

  .navigation--social {
    a {
      border: 0px;
      padding: 6px 8px 6px 9px;

      .label {
        display: none;
      }

      .icon {
        display: block;
        font-size: 1.7em;
      }

    }

  }